// User roles are used for user rights to show menu's. These string comes with the jwtToken
export enum UserRoles {
  Administrator = 'Administrator',
  BmAdministrator = 'BmAdministrator',
  LibraryEmployee = 'LibraryEmployee',
  LibraryManager = 'LibraryManager',
  PoiCoordinator = 'PoiCoordinator',
  PoiEmployee = 'PoiEmployee',
  ProgramManager = 'ProgramManager',
  PaperResponse = 'PaperResponse',
  All = '*',
}

// These strings are used in ui
export const RoleNl: Record<string, string> = {
  Administrator: 'Desan beheerder',
  BmAdministrator: 'KB-beheerder',
  ProgramManager: 'Kernteam-lid',
  PoiCoordinator: 'POI-coördinator',
  PoiEmployee: 'POI-medewerker',
  LibraryManager: 'Bibliotheekmanagement',
  LibraryEmployee: 'Bibliotheekmedewerker',
  PaperResponse: 'Papier response',
};
